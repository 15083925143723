import { isJpmCountry } from "@/helpers/constants";
import { User, Permissions, RequestPermissions, ResponsePermissions, DeveloperTypes } from "@/types/keycloak";
import { CountryCode, EnvVariables, Routes } from "@/types";

export const requestPermissionsMap = {
  request_administration: { name: "request_administration", value: "Administration" },
  request_cop_check: { name: "request_cop_check", value: "CoP Check" },
  request_transaction_history: { name: "request_transaction_history", value: "Transaction History" },
  request_mi_report_access: { name: "request_mi_report_access", value: "MI Report Access" },
  request_itsm: { name: "request_itsm", value: "ITSM" },
  request_no_access: { name: "request_no_access", value: "No Access" },
};
export const requestPermissionsKeys = Object.keys(requestPermissionsMap) as RequestPermissions[];
export const requestPermissionsOptions = Object.values(requestPermissionsMap) as {
  name: RequestPermissions;
  value: string;
}[];

export const responsePermissionsMap = {
  response_administration: { name: "response_administration", value: "Administration" },
  response_transaction_history: { name: "response_transaction_history", value: "Transaction History" },
  response_itsm: { name: "response_itsm", value: "ITSM" },
  response_account_management: { name: "response_account_management", value: "Account Management" },
  response_no_access: { name: "response_no_access", value: "No Access" },
};
export const responsePermissionsKeys = Object.keys(responsePermissionsMap) as ResponsePermissions[];
export const responsePermissionsOptions = Object.values(responsePermissionsMap) as {
  name: string;
  value: ResponsePermissions;
}[];

export const developerTypePermissionsMap = {
  internal: { name: "internal", value: "Internal" },
  external: { name: "external", value: "External" },
};
export const developerTypePermissionsKeys = Object.keys(developerTypePermissionsMap) as DeveloperTypes[];
export const developerTypePermissionsOptions = Object.values(developerTypePermissionsMap) as {
  name: string;
  value: DeveloperTypes;
}[];

export const permissionsMap = {
  ...requestPermissionsMap,
  ...responsePermissionsMap,
  ...developerTypePermissionsMap,
} as Record<Permissions, { value: string; name: Permissions }>;

export const getRequestPermissions = (user?: User) =>
  requestPermissionsKeys.filter(v => (user?.permissions || []).includes(v));

export const getResponsePermissions = (user?: User) =>
  responsePermissionsKeys.filter(v => (user?.permissions || []).includes(v));

export const getDeveloperTypePermissions = (user?: User) =>
  developerTypePermissionsKeys.filter(v => (user?.permissions || []).includes(v));

type PermissionObject = {
  countries: boolean;
  reqPermissions: RequestPermissions[];
  resPermissions: ResponsePermissions[];
  userPermissions: DeveloperTypes[];
  other: boolean;
};
const initPermissionObject = (
  userReqPerm: RequestPermissions[],
  userResPerm: ResponsePermissions[],
  devTypePerm: DeveloperTypes[],
) => {
  return ({ countries, reqPermissions, resPermissions, userPermissions, other }: PermissionObject) => {
    const isAllowedReq = userReqPerm.some(v => reqPermissions.includes(v));
    const isAllowedRes = userResPerm.some(v => resPermissions.includes(v));
    const isAllowedUser = devTypePerm.some(v => userPermissions.includes(v));

    return {
      countries,
      reqPermissions: isAllowedReq,
      resPermissions: isAllowedRes,
      other,
      isAllowed: countries && (isAllowedReq || isAllowedRes || isAllowedUser) && other,
    };
  };
};

export const permissionsByPage = (country: CountryCode, envs: EnvVariables, user?: User) => {
  const {
    hideRequestService,
    hideRequestMiReport,
    hideRequestSettings,
    hideResponseAccountPages,
    hideResponseSettingsPages,
    isAlertsEnabled,
    hideParticipantsCache,
  } = envs;

  const reqPermissionsArr = getRequestPermissions(user);
  const resPermissionsArr = getResponsePermissions(user);
  const devPermissionsArr = getDeveloperTypePermissions(user);

  const createPermissionObject = initPermissionObject(reqPermissionsArr, resPermissionsArr, devPermissionsArr);

  // ----- Dashboard -----
  const dashboardAllowed = createPermissionObject({
    countries: !(country === CountryCode.FR),
    reqPermissions: ["request_administration", "request_transaction_history", "request_itsm"],
    resPermissions: ["response_administration", "response_transaction_history", "response_itsm"],
    userPermissions: [],
    other: true,
  });
  // ----- Dashboard -----

  // ----- RequestPayee -----
  const requestPayeeAllowed = createPermissionObject({
    countries: true,
    reqPermissions: ["request_administration", "request_cop_check", "request_itsm"],
    resPermissions: [],
    userPermissions: [],
    other: !hideRequestService,
  });
  // ----- RequestPayee -----

  // ----- Req Transaction History -----
  const reqTHAllowed = createPermissionObject({
    countries: true,
    reqPermissions: ["request_administration", "request_transaction_history", "request_itsm"],
    resPermissions: [],
    userPermissions: [],
    other: !hideRequestService,
  });
  // ----- Req Transaction History -----

  // ----- Req MI Report -----
  const reqMIReportAllowed = createPermissionObject({
    countries: country === CountryCode.UK,
    reqPermissions: ["request_administration", "request_mi_report_access", "request_itsm"],
    resPermissions: [],
    userPermissions: [],
    other: !(hideRequestService || hideRequestMiReport),
  });
  // ----- Req MI Report -----

  // ----- Req MI Report(GLOBAL) -----
  const reqMIReportAllowedGlobal = createPermissionObject({
    countries: country === CountryCode.GLOBAL,
    reqPermissions: ["request_administration", "request_mi_report_access", "request_itsm"],
    resPermissions: [],
    userPermissions: [],
    other: !(hideRequestService || hideRequestMiReport),
  });
  // ----- Req MI Report(GLOBAL) -----

  // ----- Req Settings -----
  const reqMIReportSettingsAllowed = createPermissionObject({
    countries: country === CountryCode.UK,
    reqPermissions: ["request_administration", "request_mi_report_access"],
    resPermissions: [],
    userPermissions: [],
    other: !(hideRequestService || hideRequestSettings),
  });
  // ----- Req Settings -----

  // ----- Req Settings(GLOBAL) -----
  const reqMIReportSettingsAllowedGlobal = createPermissionObject({
    countries: country === CountryCode.GLOBAL,
    reqPermissions: ["request_administration", "request_mi_report_access"],
    resPermissions: [],
    userPermissions: [],
    other: !(hideRequestService || hideRequestSettings),
  });
  // ----- Req Settings(GLOBAL) -----

  // ----- Req Participants -----
  const reqParticipantsAllowed = createPermissionObject({
    countries:
      country === CountryCode.UK ||
      country === CountryCode.BF ||
      country === CountryCode.GLOBAL ||
      country === CountryCode.EPC,
    reqPermissions: ["request_administration", "request_itsm"],
    resPermissions: [],
    userPermissions: [],
    other: !hideRequestService,
  });
  // ----- Req Participants -----

  // ----- Res Transaction History -----
  const resTHAllowed = createPermissionObject({
    countries: !isJpmCountry(country),
    reqPermissions: [],
    resPermissions: ["response_administration", "response_transaction_history", "response_itsm"],
    userPermissions: [],
    other: true,
  });
  // ----- Res Transaction History -----

  // ----- Res Accounts -----
  const resAccountsAllowed = createPermissionObject({
    countries:
      country === CountryCode.UK ||
      country === CountryCode.BF ||
      country === CountryCode.GLOBAL ||
      country === CountryCode.FR ||
      country === CountryCode.EPC,
    reqPermissions: [],
    resPermissions: ["response_administration", "response_account_management"],
    userPermissions: [],
    other: !hideResponseAccountPages,
  });
  // ----- Res Accounts -----

  // ----- Res Settings -----
  const resSettingsAllowed = createPermissionObject({
    countries: country === CountryCode.UK || country === CountryCode.GLOBAL || country === CountryCode.EPC,
    reqPermissions: [],
    resPermissions: ["response_administration", "response_account_management"],
    userPermissions: [],
    other: !hideResponseSettingsPages,
  });
  // ----- Res Settings -----

  // ----- Res Participants -----
  const resParticipantsAllowed = createPermissionObject({
    countries:
      country === CountryCode.UK ||
      country === CountryCode.BF ||
      country === CountryCode.GLOBAL ||
      country === CountryCode.EPC,
    reqPermissions: [],
    resPermissions: ["response_administration", "response_itsm"],
    userPermissions: [],
    other: true,
  });
  // ----- Res Participants -----

  // ----- Settings User Management -----
  const settingsUserAllowed = createPermissionObject({
    countries: true,
    reqPermissions: ["request_administration"],
    resPermissions: ["response_administration"],
    userPermissions: [],
    other: true,
  });
  // ----- Settings User Management -----

  // ----- Settings Performance -----
  const settingsPerformanceAllowed = createPermissionObject({
    countries: !(country === CountryCode.BF || country === CountryCode.EPC),
    reqPermissions: ["request_administration", "request_itsm"],
    resPermissions: ["response_administration", "response_itsm"],
    userPermissions: [],
    other: true,
  });
  // ----- Settings Performance -----

  // ----- Settings Alerts -----
  const settingsAlertsAllowed = createPermissionObject({
    countries: !(
      country === CountryCode.BF ||
      country === CountryCode.FR ||
      country === CountryCode.EPC ||
      isJpmCountry(country)
    ),
    reqPermissions: [],
    resPermissions: ["response_administration"],
    userPermissions: [],
    other: isAlertsEnabled,
  });
  // ----- Settings Alerts -----

  // ----- Settings Participant Cache -----
  const settingsPCacheAllowed = createPermissionObject({
    countries: !(country === CountryCode.FR || isJpmCountry(country)),
    reqPermissions: ["request_administration", "request_itsm"],
    resPermissions: ["response_administration"],
    userPermissions: [],
    other: !(hideRequestService || hideParticipantsCache),
  });
  // ----- Settings Participant Cache -----

  // ----- Internal Routes -----
  const developerAllowed = createPermissionObject({
    countries: true,
    reqPermissions: [],
    resPermissions: [],
    userPermissions: ["internal", "external"],
    other: true,
  });
  // ----- Internal Routes -----

  return {
    [Routes.DASHBOARD]: dashboardAllowed,

    [Routes.REQUEST_CONFIRM_SINGLE_PAYEE]: requestPayeeAllowed,
    [Routes.REQUEST_CONFIRM_SINGLE_PAYEE_TRANSACTIONS]: requestPayeeAllowed,
    [Routes.REQUEST_CONFIRM_BATCH_PAYEE]: requestPayeeAllowed,

    [Routes.REQUEST_TRANSACTION_HISTORY]: reqTHAllowed,

    [Routes.REQUEST_MI_REPORT_GLOBAL]: reqMIReportAllowedGlobal,
    [Routes.REQUEST_MI_REPORT]: reqMIReportAllowed,
    [Routes.REQUEST_SETTINGS_MI_REPORT_GLOBAL]: reqMIReportSettingsAllowedGlobal,
    [Routes.REQUEST_SETTINGS_MI_REPORT]: reqMIReportSettingsAllowed,
    [Routes.REQUEST_SETTINGS]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.REQUEST_PARTICIPANTS]: reqParticipantsAllowed,
    [Routes.REQUEST_PARTICIPANTS_MANUAL_DCR]: reqParticipantsAllowed,

    [Routes.REQUEST_CONFIRM_PAYEE]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },
    [Routes.REQUEST]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.RESPONSE_SERVICE_HISTORY]: resTHAllowed,
    [Routes.RESPONSE_SERVICE_ACCOUNTS]: resAccountsAllowed,

    [Routes.RESPONSE_SERVICE_SETTINGS]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS_HONORIFICS]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS_DESCRIPTORS]: resSettingsAllowed,
    [Routes.RESPONSE_SERVICE_SETTINGS_LEVENSHTEIN]: resSettingsAllowed,

    [Routes.RESPONSE_SERVICE_PARTICIPANTS]: resParticipantsAllowed,
    [Routes.RESPONSE]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.USER_MANAGEMENT]: settingsUserAllowed,
    [Routes.PERFORMANCE_METRICS]: settingsPerformanceAllowed,
    [Routes.ALERTS]: settingsAlertsAllowed,
    [Routes.PARTICIPANTS_CACHE]: settingsPCacheAllowed,
    [Routes.SETTINGS]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },

    [Routes.ADMIN_ACCOUNT_SETTINGS]: {
      countries: true,
      reqPermissions: true,
      resPermissions: true,
      other: true,
      isAllowed: true,
    },
    [Routes.ADMIN_UPDATE_PASSWORD]: {
      countries: true,
      reqPermissions: true,
      resPermissions: true,
      other: true,
      isAllowed: true,
    },

    [Routes.APPS]: developerAllowed,
    [Routes.ACCOUNT_SETTINGS]: developerAllowed,
    [Routes.UPDATE_PASSWORD]: developerAllowed,
    [Routes.USER]: {
      countries: false,
      reqPermissions: false,
      resPermissions: false,
      other: false,
      isAllowed: false,
    },
  } as {
    [p: string]: ReturnType<typeof createPermissionObject>;
  };
};

export const findNearestAllowedRoute = (
  routesWithPermissions: ReturnType<typeof permissionsByPage>,
  subRoute?: string,
) => {
  for (const [route, permission] of Object.entries(routesWithPermissions)) {
    if (permission.isAllowed) {
      if (!subRoute) {
        return route;
      }
      if (subRoute && route.includes(subRoute)) {
        return route;
      }
    }
  }
  return "";
};
